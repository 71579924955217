/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const  getBaseSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro();
  filtro.perPage = 1;
  filtro.filters.addFilter(campo,valor,condicao);
  return filtro
};


export const SolucaoSCH = (perPage)=> {
    var filtro = new Filtro();
    filtro.perPage = perPage;
    filtro.fields = ['*'];

  filtro.fields = ['tipos_solucoes.descricao','tipos_solucoes.titulo as tp_titulo','solucao_id', 'solucoes.titulo', 'solucoes.tp_solucao_id','solucoes.resumo', 'solucoes.descricao as solucao_desc'];
  filtro.join.addLeft('tipos_solucoes','tipos_solucoes.tp_solucao_id','solucoes.tp_solucao_id')
  filtro.addOrder('ordem', 'asc')
  return filtro

};
