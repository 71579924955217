/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import 'aos/dist/aos.css'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import '../src/assets/css/main.scss'
import 'font-awesome/css/font-awesome.min.css'
import 'jquery/dist/jquery.min'
import 'lightbox2/dist/css/lightbox.css'
import lightbox from 'lightbox2'
import Footer from '../src/components/Footer.vue'
import ProCard from '../src/components/ProCard.vue'
import ContainerForm from '../src/components/ContainerForm.vue'
import Parallax from '../src/components/Parallax.vue'
import store from './store/index'
import {sync} from 'vuex-router-sync'
import * as VueGoogleMaps from 'vue2-google-maps'
import ProTitulo from '../src/components/Titulo.vue'
import ProModalCadastro from '../src/components/ModalCadastro.vue'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import ScrollAnimation from './assets/js/scrollanimation'
import ProAlerta from './components/ProAlerta.vue'
// import 'swiper/css/swiper.css'
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import ProSwiper from './components/ProSwiper.vue'
import OwlCarousel from 'v-owl-carousel'
import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d);
Vue.use(lightbox)
Vue.component('swiper', Swiper)
Vue.component('swiperSlide', SwiperSlide)

Vue.component('pro-swiper', ProSwiper)
Vue.component('carousel', OwlCarousel)
Vue.directive('scrollanimation', ScrollAnimation);
Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.component('pro-titulo', ProTitulo)
Vue.component('pro-card', ProCard)
Vue.component('pro-footer', Footer)
Vue.component('pro-alerta', ProAlerta)
Vue.component('pro-container-form', ContainerForm)
Vue.component('parallax', Parallax)
Vue.component('pro-modal-cadastro', ProModalCadastro)

Vue.use(Vuetify, { theme: {
  secondary: '#0a593b',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107'
} })

Vue.config.productionTip = false

sync(store, router)

// A key precisará ser alterada depois
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAb0wzUYfv-8mxhDsIS8nv7ddodU-Df5Eo',
    libraries: 'visualization'
  }
})

const vuetifyOptions = {
  icons: {
    iconfont: 'mdi'
  }
}

new Vue({
  router,
  store: store,
  render: h => h(App),
  vuetify: new Vuetify(vuetifyOptions)
}).$mount('#app')
