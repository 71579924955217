<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-dialog v-model="Mostrar" :width="width" persistent :fullscreen="fullscreen" :hide-overlay="overlay">

      <vue-draggable-resizable :resizable="false" :draggable="dragable" :parent="false" class-name="my-class"
                               :style="'margin-left:' + margem_left +'%'" v-if="dragable" :drag-handle="'.headline'">
        <v-card>
          <v-card-title class="headline popup-header" primary-title id="modalhead"
                        style="background: black; color: white">
            {{getTitulo()}}
            <v-spacer></v-spacer>
            <v-tooltip left  v-if="dragable">
              <template v-slot:activator="{ on }">
                <v-checkbox small class="inp"  dark v-on="on" color="prmary" v-model="overlay" label=""></v-checkbox>
              </template>
              <span>Habilitar fundo</span>
            </v-tooltip>

          </v-card-title>
          <v-card-text>
            <slot></slot>
          </v-card-text>
        </v-card>
      </vue-draggable-resizable>


      <v-card v-else>
        <v-card-title class="headline" primary-title id="modalhead" style="background: #F47C20; color: white">
          {{getTitulo()}}
        </v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
      </v-card>


    </v-dialog>
  </div>
</template>

<script defer>
/* eslint-disable */
  import util from '../assets/js/Util'

  export default {
    props: {
      titulo: {
        default: ''
      },
      modal: {
        default: false
      },
      fullscreen: {
        default: false
      },
      dragable: {
        default: false
      },
      width: {
        default: '50%'
      }
    },
    name: "ModalCadastro",

    data() {
      return {
        overlay: false,
        margem_left: '25',
        classe: '',
        util
      }
    },
    methods: {
      getTitulo() {
        if (util.validaCampo(this.titulo)) {
          return this.titulo
        } else {
          return this.$router.currentRoute.meta
        }
      },
    },
    computed: {
      Mostrar: {
        get() {
          return this.modal
        },
        set() {
          return this.Mostrar
        }
      },
    },
    watch: {
      modal: function () {
        if (this.modal) {
          var _this = this
          var inter = setInterval(function () {
            var mywidth = document.getElementById('drg')
            if (mywidth !== null) {
              var width = window.screen.width
              var w = mywidth.clientWidth
              var res = width - w
              res = res / 2
              res = (res * 100) / width
              _this.margem_left = parseInt(res)
            }
            clearInterval(inter)
          }, 500)
        }
      }
    }
  }
</script>

<style scoped>
</style>
