/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export default class Solucao {

  constructor () {
    this.solucao_id = uuidV4();
    this.titulo= '';
    this.descricao= '';
    this.tp_solucao_id= '';
    this.solucoes_detalhes= [];
    this.imagens= [];
    this.urls_imagem= [];
  }
}
