/* eslint-disable */
import {v4 as uuidV4} from 'uuid'

/**
 * Created by WENDEL on 31/08/2017.
 */
export default class Plano {

  constructor () {
    this.plano_id = uuidV4();
    this.descricao = ''
    this.abreviado = ''
    this.cidade_id = ''
    this.bloqueado = ''
    this.valor = 0
    this.vl_instalacao = 0
    this.tp_internet = ''
  }

}
