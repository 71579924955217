/* eslint-disable */
import {lista, processar, pegaLista, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {TipoSolucaoSCH, getBaseSCH} from "../../search/TipoSolucaoSCH"
import {EventBus} from '../../helpers/event-bus'
import TipoSolucao from '../../models/tipo_solucao'

const url = '/tipo_solucao';

const state = {
  all: [],
  tipo_solucao: new TipoSolucao(),
  filtro: new Filtro()
};
const getters = {
  listaTiposSolucoes: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaTipoSolucao: (state) => {
    return state.tipo_solucao
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  setTipoSolucao({commit}, tipo_solucao) {
    commit('SET_TIPO_SOLUCAO', tipo_solucao)
  },

  async getItem({commit}, {id,onSucesso, onErro}) {
    try {
      var filtro = getBaseSCH('tp_solucao_id', id, '=');
      const list = (await pegaDados(url + '/get', filtro)).data;
      commit('SET_TIPO_SOLUCAO', list.data[0]);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', TipoSolucaoSCH(perPage))
  },

  async setAllTiposSolucoes({commit}, {onSucesso, onErro}) {
    try {
      var filtro = TipoSolucaoSCH('*')
      const list = (await pegaDados(url+'/get', filtro)).data;
      commit('SET_TIPOS_SOLUCOES', list);
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
  async processarTipoSolucao({commit}, {tipo_solucao,acao,onSucesso, onErro}) {
    try {
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , tipo_solucao, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      // this.dispatch('tipo_solucao/setAllTiposSolucoes');
      // EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'TipoSolucao')

      onSucesso()
    } catch (error) {
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data);
      // EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'TipoSolucao')
    }
  },

};

const mutations = {
  SET_TIPOS_SOLUCOES(state, tipo_solucaos) {
    state.all = tipo_solucaos
  },
  SET_TIPO_SOLUCAO(state, tipo_solucao) {
    state.tipo_solucao = tipo_solucao
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

