import { render, staticRenderFns } from "./ProCard.vue?vue&type=template&id=16be0634&scoped=true"
import script from "./ProCard.vue?vue&type=script&defer=true&lang=js"
export * from "./ProCard.vue?vue&type=script&defer=true&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16be0634",
  null
  
)

export default component.exports