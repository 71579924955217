/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import home from './modules/home'
import plano from './modules/planos'
import banner from './modules/banners'
import cidade from './modules/cidades'
import solucao from './modules/solucoes'
import galeria from './modules/galerias'
import cliente from './modules/clientes'
import contato from './modules/contatos'
import tipo_solucao from './modules/tipos_solucoes'
Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    home,
    plano,
    banner,
    cidade,
    galeria,
    cliente,
    solucao,
    contato,
    tipo_solucao,
  }
})
