/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export default class Banner {

  constructor () {
    this.banner_id = uuidV4();
    this.link = ''
    this.ordem = 0
    this.imagens= [];
    this.urls_imagem= [];
  }
}
