<template>
  <div class="prllx">
    <div :id="id" class="bgParallax" data-speed="15">
      <article>
        <slot></slot>
      </article>
    </div>
  </div>
</template>

<script defer>
/* eslint-disable */
  export default {
    name: "Parallax",
    props: ['id'],
    mounted() {
      var $ = require('jquery')
      $('div.bgParallax').each(function () {
        var $obj = $(this)
        $(window).scroll(function () {
          var yPos = -($(window).scrollTop() / $obj.data('speed'))
          var bgpos = '25% ' + yPos + 'px'
          $obj.css('background-position', bgpos)
        })
      })

    }
  }
</script>

