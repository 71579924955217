/* eslint-disable */
import { Anexo } from '../models/anexo'
import empty from '../assets/img/empty.webp'
import miscMixin from './misc'

export default {
  mixins: [miscMixin],
  data () {
    return {
      // showCropper: false,
      imagens: [],
      IMG: null
    }
  },
  methods: {
    getUrlImagem(item, tam) {
      var src = empty
      if (this.validarCampo(item.urls_imagem)) {
        if (item.urls_imagem.length > 0) {
          src = this.selecionaImagem(item.urls_imagem, tam)
        }
      } else {
        if (item.imagens !== undefined && item.imagens.length > 0) {
          src = item.imagens[0].src
        } else if (item.urls !== undefined && item.urls.length > 0) {
          src =  item.urls.find(list=>list.tamanho === tam).url
        } else {
          if (item.length > 0) {
            src = item[0].src
          }
        }
      }
      return src
    },
    selecionaImagem (urls, tamanho, anexoId) {
      let url
      let urlsPesquisa
      if (anexoId !== undefined) {
        let tempAnexo = urls.find(x => x.anexo === anexoId)
        if (tempAnexo !== null && tempAnexo !== undefined) {
          urlsPesquisa = tempAnexo.urls
        }
      } else {
        if (urls !== undefined && urls.length > 0) {
          var achou = urls.find(c=>c.capa === 1)
          if (achou !== undefined) {
            urlsPesquisa = achou.urls
          } else {
            urlsPesquisa = urls[0].urls
          }
        }
      }
      if (urlsPesquisa !== undefined && urlsPesquisa.length > 0) {
        url = urlsPesquisa.find(x => x.tamanho === tamanho)

        if (url === undefined) {
          url = urlsPesquisa.find(x => x.tamanho === 'original')
        }
      } else {
        url = {
          url: empty
        }
      }
      return url.url
    },
    preencheObjeto(objeto, tamanho) {
      this.imagens = [];
      if (objeto.urls_imagem !== undefined) {
        objeto.urls_imagem.forEach(u => {
          let anexo = new Anexo(this.selecionaImagem(objeto.urls_imagem, tamanho, u.anexo), '', u.capa, true);
          anexo.anexo_id = u.anexo;
          if (anexo.url !== empty) {
            this.imagens.push(anexo)
          }
        })
      }

    },
    removeImagemObjeto (id) {
      let tempFiltro = this.imagens.filter((valor) => {
        return valor.anexo_id !== id
      })
      this.imagens = tempFiltro
    },
    carregarVariasImagens (input, mantemExtensao) {
      let _this = this
      let maximoTamanho = 3 * 1024 * 1024 // maximo 3 MB
      if (input.files) {
        let quantImagens = input.files.length
        for (let i = 0; i < quantImagens; i++) {
          if (input.files[i].size <= maximoTamanho) {
            let reader = new FileReader()
            reader.onload = function (event) {
              let img = document.createElement('img')
              img.src = event.target.result
              _this.imagens.push(new Anexo('', img.src, false, mantemExtensao))
            }
            reader.readAsDataURL(input.files[i])
          } else {
            // colocar um alert melhor aqui depois
            alert('Arquivo muito grande. Máximo 3MB.')
          }
        }
      }
    },
    fecharModalCropper(fechar) {
      // this.showCropper = fechar
      this.IMG = null
    },
    erroAdd (erros) {
      alert.log(erros)
    },
    carregarImagens (mantemExtensao) {
      let _this = this
      let $ = require('jquery')
      $('#addFotoGaleria').on('change', function () {
        _this.carregarVariasImagens(this, mantemExtensao)
        $('#addFotoGaleria').off('change')
      })
    }
  }
}
