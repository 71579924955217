<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div id="inspire">
    <v-system-bar dark :height="25" window fixed id="system_bar">
      <!--systembar-->
      <div style="float: left; margin-left: 10%">
        <v-btn :name="'nome'+sys.icon"  @click="IrParaUrl(sys.link)" small text color="white" v-for="(sys, index) in systembar"
               :key="'sys' + index">
          <v-icon small color="gray">{{sys.icon}}</v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn name="cidade"  style="float: right;margin-right: 10% " small text disabled class="desktop">
        <v-icon small color="gray">fa fa-map-marker</v-icon>
        {{cidade_nome}}
      </v-btn>
    </v-system-bar>

    <v-card class="overflow-hidden toobar">
      <v-app-bar absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7"
                 class="appScrolling" id="navapp">
        <v-toolbar-title class="margem_left">
          <a name="logo"  @click="irPara('/', 'home',true)">
            <div class="div_logo"></div>
          </a>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon dark @click.stop="drawer = !drawer" class="mobile"></v-app-bar-nav-icon>

        <!--MENU DESKTOP-->
        <v-toolbar-items class="toobar_dash margem_right desktop">
          <v-btn name="menu"  text @click="clicou(item)" :id="item.id" v-for="(item, index) in menus" :key="'mn_'+index">
            <span class="btn_toolbar_span">{{item.titulo}}</span>
          </v-btn>
        </v-toolbar-items>
      </v-app-bar>

      <!--MENU MOBILE-->
      <v-navigation-drawer v-model="drawer" absolute temporary class="lista_menu" dark>
        <v-list nav dense>
          <v-list-item-title class="text-h6">
            Menu
          </v-list-item-title>
          <v-divider></v-divider>
          <v-list-item @click="clicou(item)" v-for="(item, index) in menus" :key="'nav_' + index">
            <v-list-item-title>{{item.titulo}}</v-list-item-title>
          </v-list-item>
        </v-list>
        <div style="text-align: center">
          <v-btn name="fone"  outlined dense @click="chamada('tel:3333226363')">(33)3322-6363</v-btn>
        </div>

      </v-navigation-drawer>

      <!--<v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="100vh">-->
      <v-sheet id="scrolling-techniques-7" class="overflowyauto">
        <div>
          <router-view/>
          <!--<br>-->
          <Footer></Footer>
        </div>
      </v-sheet>
    </v-card>
  </div>
</template>

<script defer>
/* eslint-disable */
  import router from './router'
  import miscMixin from './helpers/misc'
  import {mapActions, mapGetters} from "vuex";
  export default {
    name: 'Dash',
    mixins: [miscMixin],
    data: () => ({
      drawer: false,
      mn_trk_1: false,
      mn_trk: false,
      menus: [
        {id: 'aempresa', path: '/a-empresa', location: false, funcao: 'irPara', titulo: 'A Prodata'},
        {
          id: 'central_assinante',
          path: 'http://cliente.prodatanet.com.br/',
          location: true,
          funcao: 'IrParaUrl',
          titulo: 'Área do Cliente'
        },
        {id: 'planos', path: '/planos', location: false, funcao: 'irPara', titulo: 'Internet'},
        {id: 'automacao', path: '/automacao', location: false, funcao: 'irPara', titulo: 'Automação'},
        {id: 'Contato', path: '/contato', location: false, funcao: 'irPara', titulo: 'Contato'},

      ],
      systembar: [
        {icon: 'fa fa-facebook', title: 'Facebook', link: 'https://www.facebook.com/prodatainternet/'},
        {icon: 'fa fa-instagram', title: 'Instagram', link: 'https://www.instagram.com/prodata_net/'},
        {icon: 'fa fa-envelope-o', title: 'Email', link: 'mailto:prodatafinanceiro@gmail.com?Subject=Hello%20again'},
      ],
    }),
    components: {
      Footer: () => import('./components/Footer')
    },
    methods: {
      ...mapActions('banner', ['setAllBanners']),
      ...mapActions('cidade', ['setAllCidades', 'setCidadeNome','getItem']),
      ...mapActions('plano', ['setAllPlanos']),
      ...mapActions('galeria', ['setAllGalerias']),
      ...mapActions('solucao', ['setAllSolucoes']),
      clicou(item) {
        sessionStorage.setItem('item_menu_id',item.id)
        this[item.funcao](item.path, item.id, item.location)
      },
      chamada(path) {
        var a = document.createElement('a')
        a.href = path
        a.click()
      },
      irPara(path, id, location) {
        sessionStorage.setItem('secao_id', 'inspire')
        this.scrollTo('inspire')
        this.isActive(id)
        if (location) {
          sessionStorage.setItem('item_menu_id',id)
          window.location = path
        } else {
          this.trocaIdToobar()
          router.push({path: path}).catch(err => {
          })
        }

      },
    },
    created() {

      if (this.validarCampo(sessionStorage.getItem('cidade_id'))) {
        this.getItem({
          id: sessionStorage.getItem('cidade_id'),
          onSucesso: (retorno) => {
            this.setCidadeNome(retorno.nome)
          },
          onErro: () => {
          }
        })
      } else {
        this.setCidadeNome('Caratinga')
      }
    },
    mounted() {
      if (this.validarCampo(sessionStorage.getItem('item_menu_id'))) {
        this.isActive(sessionStorage.getItem('item_menu_id'))
      }

      var inspire = document.getElementById("inspire");
      window.onscroll = function () {
        "use strict";
        if (document.documentElement.scrollTop >= 50) {
          inspire.classList.add("scroll");
        } else {
          inspire.classList.remove("scroll");
        }
      };
    },
    computed: {
      ...mapGetters('cidade', {cidade_nome: 'pegaCidadeNome',listaCompletaCidades: 'listaCompletaCidades'}),
    }
  }
</script>
