/* eslint-disable */
import {lista, processar, pegaLista, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {ClienteSCH, getBaseSCH} from "../../search/ClienteSCH"
import {EventBus} from '../../helpers/event-bus'
import Cliente from '../../models/cliente'

const url = '/cliente';

const state = {
  all: [],
  cliente: new Cliente(),
  filtro: new Filtro()
};
const getters = {
  listaClientes: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaCliente: (state) => {
    return state.cliente
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  setCliente({commit}, cliente) {
    commit('SET_CLIENTE', cliente)
  },
  async getItem({commit}, id) {
    var filtro = getBaseSCH('cliente_id', id, '=');
    const list = (await pegaLista(url, filtro)).data;
    commit('SET_CLIENTES', list.data[0]);
    EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', ClienteSCH(perPage))
  },

  async setAllClientes({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_CLIENTES', list);
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
};

const mutations = {
  SET_CLIENTES(state, clientes) {
    state.all = clientes
  },
  SET_CLIENTE(state, cliente) {
    state.cliente = cliente
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

