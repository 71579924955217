<template>
    <v-card>
      <v-card-text>
            <slot></slot>
      </v-card-text>
    </v-card>
</template>

<script defer>
/* eslint-disable */
  export default {
    name: "ProCard"
  }
</script>

<style scoped>

</style>
