/* eslint-disable */
import {lista, pegaDados, pegaLista, processar} from '../../services/GenericAPI'
import {Filtro, Where} from '../../models/filtro/filtro'
import {getBaseSCH, SolucaoSCH} from '../../search/SolucaoSCH'
import {EventBus} from '../../helpers/event-bus'
import Solucao from '../../models/solucao'

const url = '/solucao'

const state = {
  all: [],
  tipos_solucoes: [],
  solucao: new Solucao(),
  filtro: new Filtro()
}
const getters = {
  listaTiposSolucoes: (state) => {
    return state.tipos_solucoes !== undefined ? state.tipos_solucoes : []
  },
  listaSolucoes: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaSolucao: (state) => {
    return state.solucao
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}


const actions = {
  setSolucao({commit}, solucao) {
    commit('SET_SOLUCAO', solucao)
  },
  async getItem({commit}, {id, onSucesso, onErro}) {
    try {
      var filtro = getBaseSCH('solucao_id', id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data;
      commit('SET_SOLUCAO', list.data[0])
      onSucesso( list.data[0])
    } catch (error) {
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', SolucaoSCH(perPage))
  },

  async setTiposSolucoes({commit}) {
    const list = (await lista(url+'/get_tipos_solucoes')).data;
    commit('SET_TIPOS_SOLUCOES', list)
  },
  async setAllSolucoes({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_SOLUCOES', list)

      onSucesso()
    } catch (error) {
      onErro()
    }

  },
  async processarSolucao({commit}, {solucao, acao, onSucesso, onErro}) {
    try {
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , solucao, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data);
    }
  },
  processarExtras({commit}, {item, acao, tipo}) {
    if (acao === 'gravar') {
      commit('ADICIONA_' + tipo, item)
    } else if (acao === 'alterar') {
      commit('ALTERA_' + tipo, item)
    } else if (acao === 'remover') {
      commit('REMOVE_' + tipo, item)
    }
  },

}

const mutations = {
  SET_TIPOS_SOLUCOES(state, solucoess) {
    state.tipos_solucoes = solucoess
  },
  SET_SOLUCOES(state, solucoess) {
    state.all = solucoess
  },
  SET_SOLUCAO(state, solucao) {
    state.solucao = solucao
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

  // Secao
  REMOVE_PORTIFOLIO(state, item) {
    let pos = state.solucao.solucoes_detalhes.indexOf(item)
    state.solucao.solucoes_detalhes.splice(pos, 1)
  },
  ALTERA_PORTIFOLIO(state, item) {
    var c = state.solucao.solucoes_detalhes.find(lista => lista.solucao_detalhe_id === item.solucao_detalhe_id)
    let pos = state.solucao.solucoes_detalhes.indexOf(c)
    state.solucao.solucoes_detalhes.splice(pos, 1, item)
  },
  ADICIONA_PORTIFOLIO(state, item) {
    state.solucao.solucoes_detalhes.splice(state.solucao.solucoes_detalhes.length, 1, item)
  },


}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

