/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dash from "./Dash";

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '',
      component: Dash,
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: "about" */ './views/Home/Home.vue')
        },
        {
          path: '/a-empresa',
          name: 'A Empresa',
          component: () => import(/* webpackChunkName: "about" */ './views/Empresa/Empresa.vue')
        },
        {
          path: '/planos',
          name: 'Planos',
          component: () => import(/* webpackChunkName: "about" */ './views/Planos/Planos.vue')
        },
        {
          path: '/automacao',
          name: 'Automacao',
          component: () => import(/* webpackChunkName: "about" */ './views/Automacao/Automacao.vue')
        },
        {
          path: '/automacao/:id',
          name: 'AutomacaoDdetalhes',
          component: () => import(/* webpackChunkName: "about" */ './views/Automacao/AutomacaoDetalhes.vue')
        },
        {
          path: '/contato',
          name: 'Contato',
          component: () => import(/* webpackChunkName: "about" */ './views/Contato/Contato.vue')
        },
        {
          path: 'galeria',
          name: 'Galeria',
          component: () => import(/* webpackChunkName: "about" */ './views/Galeria/Galeria.vue')
        },
        {
          path: '/galeria/:id',
          name: 'GaleriaId',
          component: () => import(/* webpackChunkName: "about" */ './views/Galeria/Galeria.vue')
        },
        {
          path: '*',
          name: 'PaginaNaoEncontrada',
          component: () => import(/* webpackChunkName: "about" */ './views/404.vue')
        },
      ]
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "about" */ './views/404.vue')
    }
  ]
})
