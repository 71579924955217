/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export default class Cliente {

  constructor () {
    this.cliente_id = uuidV4();
    this.link = ''
    this.destaque = false
    this.imagens= [];
    this.urls_imagem= [];
  }
}
