<template>
  <div>
    <h1 v-if="tag==='h1'" class="texto_titulo">{{titulo}}  <v-icon v-if="tem_link" size="20" >fa fa-external-link</v-icon></h1>
    <h2 v-else-if="tag==='h2'" class="texto_titulo">{{titulo}}  <v-icon v-if="tem_link" size="20" >fa fa-external-link</v-icon></h2>
    <h3 v-else-if="tag==='h3'" class="texto_titulo">{{titulo}}  <v-icon v-if="tem_link" size="20" >fa fa-external-link</v-icon></h3>
    <h4 v-else-if="tag==='h4'" class="texto_titulo">{{titulo}}  <v-icon v-if="tem_link" size="20" >fa fa-external-linkt</v-icon></h4>
    <span v-else class="texto_titulo">{{titulo}} <v-icon v-if="tem_link" size="20" >fa ffa-external-link</v-icon></span>
    <slot></slot>
    <div class="linhaTituloSimples" :style="'width: ' + width ">
      <!--<v-icon v-if="icone" size="20" class="titulo_icone">fa fa-rocket</v-icon>-->
    </div>
  </div>
</template>

<script defer>
/* eslint-disable */
  export default {
    name: "Titulo",
    props: {
      titulo:{default: 'Titulo'},
      tag:{default: 'h1'},
      width:{default: '50%'},
      icone:{default: true},
      tem_link:{default: false},
    }
  }
</script>

<style scoped>
  .titulo_icone{
    float: right;
    margin-top: -10px;
    color: #963700;
    background: #eeeeee;
    transform: rotate(45deg);
  }
  .texto_titulo,  .texto_titulo i{
    color: #F47C20;
  }

  .linhaTituloSimples {
    height: 2px;
    margin-bottom: 1px;
    background: linear-gradient(299deg, #ffc107, #ff9800, #ff570a);
    background-size: 800% 800%;

    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
  }


</style>
