/* eslint-disable */
import {v4 as uuidV4} from 'uuid'

export default class Cidade {

  constructor () {
    this.cidade_id = uuidV4();
    this.nome = ''
    this.bloqueada = 0
  }
}
