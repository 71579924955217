/* eslint-disable */
import {lista, processar, pegaLista, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";

import {HomeSCH, getHomeSCH, getBaseSCH} from "../../search/HomeSCH"
import {EventBus} from '../../helpers/event-bus'
import {Home} from '../../models/home'

const url = '/home'

const state = {
  all: [],
  filtro: new Filtro(),
  home: new Home(),
  pagina:[],
}

const getters = {
  pegaHome: (state) => {
    return state.home
  },
  pegaPagina: (state) => {
    return state.pagina
  },
}

const actions = {

  setPagina({commit}, pagina) {
    commit('SET_PAGINA', pagina)
  },

  async setAllHome({commit}, {onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data

      if (list.data.length > 0) {
        const list2 = (await pegaDados(url+'/get_secao', {home_id: list.data[0].home_id})).data
        commit('SET_HOME', list2)
      } else {
        commit('SET_HOME', new Home())
      }
      onSucesso(list.data[0])
    } catch (error) {
      onErro()
    }

  },
  async getItem({commit}, id) {
     var filtro = getBaseSCH('home_id', id, '=')
     const list = (await pegaDados(url+'/get', filtro)).data
      commit('SET_HOME', list.data[0])
     EventBus.$emit('GET_CONCLUIDO', true)
  },

  async getPagina({commit}, {id,onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get_secao_page', {referencia_id: id})).data
      commit('SET_PAGINA', list)
      onSucesso(list)
    } catch (error) {
      onErro()
    }

  },
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.all = subgrupos
  },

  SET_HOME(state, home) {
      state.home = home
  },

  SET_PAGINA(state, pagina) {
      state.pagina = pagina
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
