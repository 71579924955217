/* eslint-disable */
import {pegaDados} from '../../services/GenericAPI'
const state = {};
const getters = {};

const actions = {
  async processarContato({commit}, {contato, onSucesso, onErro}) {
    try {
      let res = await pegaDados('form', contato);
      onSucesso()
    } catch (error) {
      onErro()
      console.log(error.response.data);
    }
  },

};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

