/* eslint-disable */
import {lista, processar, pegaLista, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {PlanoSCH, getBaseSCH} from "../../search/PlanoSCH"
import {EventBus} from '../../helpers/event-bus'
import Plano from '../../models/plano'

const url = '/planos';

const state = {
  all: [],
  allLista: [],
  plano: new Plano(),
  filtro: new Filtro()
};
const getters = {
  listaPlanos: (state) => {
    return state.all !== undefined ? state.all : []
  },
  listaCompletaPlanos: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },
  pegaPlano: (state) => {
    return state.plano
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  setPlano({commit}, plano) {
    commit('SET_PLANO', plano)
  },
  // searchItem({commit}, search) {
  //   state.filtro.filters.addFilter('descricao' ,
  //     "%"+search+"%",
  //     'like'
  //   )
  // },
  async getItem({commit}, id) {
    var filtro = getBaseSCH('plano_id', id, '=');
    const list = (await pegaDados(url+'/get', state.filtro)).data;
    // const list = (await pegaLista(url+'/get')).data;
    commit('SET_PLANOS', list.data[0]);
    EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', PlanoSCH(perPage))
  },

  async setListaCompleta({commit}) {
    const list = (await lista(url+'/get_all')).data;
    commit('SET_ALL_PLANOS', list);
  },
  async setAllPlanos({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_PLANOS', list);
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
  async processarPlano({commit}, {plano,acao,onSucesso, onErro}) {
    try {
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , plano, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data);
    }
  },

};

const mutations = {
  SET_ALL_PLANOS(state, planos) {
    state.allLista = planos
  },
  SET_PLANOS(state, planos) {
    state.all = planos
  },
  SET_PLANO(state, plano) {
    state.plano = plano
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

