<template>
  <div id="rodape" >
    <v-footer text-xs-center style="padding: 0px;background: transparent ">
      <div class="footer_meio telefones-atendimento">
        <v-container>
          <v-row justify="center" no-gutters>
            <v-col cols="12" sm="6" md="4" lg="4">
              <div style="margin-top: 25%; text-align: center;"  class="footer_atend">
                <v-row justify="center" >
                  <v-col cols="12" style="text-align: center!important;" >
                    <h3 data-aos="fade-right" >Atendimento ao Cliente</h3>
                  </v-col>
                  <v-col cols="12">
                      <div v-for="(item,index) in contatos" :key="'cont'+index">
                       <div style="width: 300px; text-align: left; margin: 2%" >
                         <v-btn :name="item.icon"  outlined rounded block dark @click="IrParaUrl(item.link)" style="justify-content: initial">
                           <v-icon style="padding-right: 3%">{{item.icon}}</v-icon>
                           {{item.titulo}}
                         </v-btn>
                       </div>
                      </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4"  lg="4">
            <v-row justify="center">
              <v-col cols="12">

                <div class="footer_btn">
                 <div style="text-align: center!important; width: 100%">
                   <h3 data-aos="fade-right" style="text-align: center!important;">Redes Sociais</h3>
                 </div>
                  <!--<br>-->
                  <v-btn :name="'redes_sociais'+index"  @click="irPara(sys.link)" text color="white" data-aos="fade-down"
                         v-for="(sys, index) in systembar" :key="'sys' + index">
                    <v-img :src="sys.src" class="footer_img_redes"></v-img>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12">
                <site-seguro url="https://www.prodatanet.com.br/"></site-seguro>
              </v-col>
            </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="4"  lg="4" class="desktop"  data-aos="fade-left">
              <div style="padding-top: 25%;" data-aos="fade-left">
                <pro-gmap height="250"></pro-gmap>
              </div>

            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="footer_bottom">
          <span class="text-center">
            Copyrigth © 2017 - {{ new Date().getFullYear() }}  Prodata Informática. Todos os direitos reservados.
          </span>
      </div>
    </v-footer>
  </div>
</template>

<script defer>
/* eslint-disable */
import {mapActions, mapGetters} from "vuex";
import miscMixin from "../helpers/misc";
import miscImageCropper from "../helpers/img-cropper";
import ico_Facebook from '../assets/img/icon/ico_Facebook.webp'
import ico_instagram from '../assets/img/icon/ico_instagram.webp'
import ico_whatsapp from '../assets/img/icon/ico_whatsapp.webp'
import ico_mail from '../assets/img/icon/ico_mail.webp'
export default {
  mixins: [miscMixin, miscImageCropper],
    name: 'ProFooter',
  components: {
    ProGmap: () => import('./ProGmap.vue'),
    SiteSeguro: () => import('./SiteSeguro/SiteSeguro.vue'),
  },
  data: () => ({
    systembar : [
      {icon: 'fa fa-facebook', title: 'Facebook', link: 'https://www.facebook.com/prodatainternet/', src:ico_Facebook},
      {icon: 'fa fa-instagram', title: 'Instagram', link: 'https://www.instagram.com/prodata_net/', src:ico_instagram},
      {icon: 'fa fa-envelope-o', title: 'Email', link: 'mailto:prodatafinanceiro@gmail.com?Subject=Hello%20again', src:ico_mail},
    ],
    contatos: [
      {icon:'fa fa-phone', titulo: ' 0800 031 0113', link:'tel:08000310113'},
      {icon:'fa fa-phone', titulo: '(33) 3322-6363 - Fixo', link:'tel:3333226363'},
      {icon:'fa fa-whatsapp ', titulo: '(33) 3322-6363 - WhatsApp',  link:'https://web.whatsapp.com/send?phone=+553333226363'},
    ]
  }),
    methods: {
      ...mapActions('empresa', ['setEmpresa', 'getItem','setFiltro','setAllEmpresa']),
      irPara(path){
        var a = document.createElement('a')
        a.target = '_blank'
        a.href = path
        a.click()
      }
    },
  created(){
    // this.setFiltro(1)
    // this.setAllEmpresa({
    //   onSucesso: (retorno) => {
    //     this.setEmpresa(retorno.data[0])
    //     this.preencheObjeto(this.empresa, 'original')
    //     this.show_list = true
    //   },
    //   onErro: () => this.show_list = false
    // })
  },
  computed: {
    // ...mapGetters('empresa', {empresa: 'pegaEmpresa',filtro: 'pegaFiltro'}),
    // getEmdereco() {
    //   var endereco = ''
    //   var logrado = this.validarCampo(this.empresa.logrado) ? (this.empresa.logrado + ', ') : ''
    //   var numero = this.validarCampo(this.empresa.numero) ? (this.empresa.numero + ' - ') : ''
    //   var bairro = this.validarCampo(this.empresa.bairro) ? (this.empresa.bairro + ' - ') : ''
    //   var cidade = this.validarCampo(this.empresa.cidade) ? (this.empresa.cidade + ' - ') : ''
    //   var uf = this.validarCampo(this.empresa.uf) ? (this.empresa.uf + ' - '): ''
    //   var cep = this.validarCampo(this.empresa.cep) ? this.maskCep(this.empresa.cep) : ''
    //   endereco =  logrado + numero + bairro + cidade + uf + cep
    //   return endereco
    // }
  },
  }
</script>


<style lang="scss" scoped>
  .tel-footer{
    font-size: 18px;
  }

  .telefones-atendimento{
    line-height: 30px;
    font-family: NexaLigth, Sans-Serif;
  }
  .telefones-atendimento h3{
    font-family: NexaLigth, Sans-Serif;
    font-size: 25px ;
    text-align: left;
    line-height: 35px;
    font-weight: 400;

  }
  .telefones-atendimento li{
    list-style-type:none;
  }
  .telefones-atendimento a{
    text-decoration: none;
    color: white;
  }

</style>
