<template>
  <div>
   <v-card height="650px">
     <div class="thumb-example">
       <!-- swiper1 -->
         <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
           <swiper-slide class="slide-1" v-for="(item, index) in lista.urls_imagem" :key="'item' + index">
             <a  name="slide" :href="selecionaImagem(lista.urls_imagem, 'original',item.anexo)" data-lightbox="image-1" data-title="">
               <v-img :src="selecionaImagem(lista.urls_imagem, 'original',item.anexo)"
                      :lazy-src="selecionaImagem(lista.urls_imagem, '50',item.anexo)"
                      height="100%" contain></v-img>
             </a>
           </swiper-slide>

           <v-btn name="next"  dark icon class="swiper-button-next " slot="button-next"></v-btn>
           <v-btn name="prev"  dark icon class="swiper-button-prev " slot="button-prev"></v-btn>
         </swiper>
       <br>
       <v-divider></v-divider>
         <!-- swiper2 Thumbs -->
         <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
           <swiper-slide class="slide-1" v-for="(thumbs, index) in lista.urls_imagem" :key="'Thumbs' + index">
             <v-img :src="selecionaImagem(lista.urls_imagem, '200',thumbs.anexo)"
                    :lazy-src="selecionaImagem(lista.urls_imagem, '50', thumbs.anexo)" height="100%" contain></v-img>
           </swiper-slide>

         </swiper>
       <br>
     </div>
   </v-card>
  </div>

</template>

<script defer>
/* eslint-disable */
import miscMixin from "../helpers/misc";
import miscImageCropper from '../helpers/img-cropper'
  export default {
    mixins: [miscMixin, miscImageCropper],
    name: 'ProSwiper',
    props:['lista'],
    data: () => ({
      swiperOptionTop: {
        loop: false,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        loop: false,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
      }
    }),
    computed:  {
    },

    mounted() {
      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.$swiper
        const swiperThumbs = this.$refs.swiperThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      })
    }
  }
</script>
<style lang="scss" scoped>

  .swiper-button-next, .swiper-button-prev{
    display: none;
  }
  .thumb-example:hover .swiper-button-next,
  .thumb-example:hover .swiper-button-prev{
    color: orangered;
    display: block;
    transition: 1s ease-in-out ;
  }
  .thumb-example {
    position: relative;
    height: 600px;
    /*background-color: #1a1a1a;*/
    z-index: 0!important;
    padding: 5px;
  }
  .swiper {
    .swiper-slide {
      background-size: cover;
      background-position: center;
      &.slide-1 {
        /*background-color: #2d2d2d;*/
        /*background-image:url('../assets/img/temp/fundo.webp');*/
      }
    }
    &.gallery-top {
      height: 80%;
      width: 100%;
    }
    &.gallery-thumbs {
      height: 20%;
      box-sizing: border-box;
      padding: 10px 0px;
    }
    &.gallery-thumbs .swiper-slide {
      width: 15%;
      height: 100%;
      opacity: 0.4;
    }
    &.gallery-thumbs .swiper-slide-active {
      opacity: 1;
    }
  }
</style>
