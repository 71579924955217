/* eslint-disable */
import {lista, processar, pegaLista, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {CidadeSCH, getBaseSCH} from "../../search/CidadeSCH"
import {EventBus} from '../../helpers/event-bus'
import Cidade from '../../models/cidade'

const url = '/cidades';

const state = {
  all: [],
  cidade_nome: 'Caratinga',
  allLista: [],
  cidade: new Cidade(),
  filtro: new Filtro()
};
const getters = {
  listaCidades: (state) => {
    return state.all !== undefined ? state.all : []
  },
  listaCompletaCidades: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },
  pegaCidade: (state) => {
    return state.cidade
  },
  pegaCidadeNome: (state) => {
    return state.cidade_nome
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  setCidadeNome({commit}, cidade) {
    commit('SET_CIDADE_NOME', cidade)
  },
  setCidade({commit}, cidade) {
    commit('SET_CIDADE', cidade)
  },
  async getItem({commit}, {id,onSucesso, onErro}) {
    // var filtro = getBaseSCH('cidade_id', id, '=');
    // const list = (await pegaDados(url+'/get', filtro)).data;
    // commit('SET_CIDADES', list.data[0]);
    // EventBus.$emit('GET_CONCLUIDO', true)

    try {
      var filtro = getBaseSCH('cidade_id', id, '=');
      const list = (await pegaDados(url + '/get', filtro)).data;
      commit('SET_CIDADE', list.data[0]);
      onSucesso(list.data[0])
    } catch (error) {
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', CidadeSCH(perPage))
  },

  async setCidadeListaCompleta({commit},{onSucesso}) {
    const list = (await lista(url+'/get_all')).data;
    commit('SET_ALL_CIDADES', list);
    onSucesso()
  },
  async setAllCidades({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_CIDADES', list);
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
  async processarCidade({commit}, {cidade,acao,onSucesso, onErro}) {
    try {
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , cidade, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data);
    }
  },

};

const mutations = {
  SET_ALL_CIDADES(state, cidades) {
    state.allLista = cidades
  },
  SET_CIDADES(state, cidades) {
    state.all = cidades
  },
  SET_CIDADE_NOME(state, cidades) {
    state.cidade_nome = cidades
  },
  SET_CIDADE(state, cidade) {
    state.cidade = cidade
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

