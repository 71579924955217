/* eslint-disable */
export class Filtro {
  constructor() {
    this.filters = new Filters()
    this.join = new Join()
    this.orders = []
    this.fields = []
    this.perPage = 15
    this.page = 1
    this.orderRaw = ''
    this.limit = ''
  }

  addOrder(orderby, direction) {
    this.orders.push(new Order(orderby, direction))
  }
}

export class Filters {
  constructor() {
    this.filter = []
    this.filterRaw = new FilterRaw()
    this.search = []
  }

  addFilter(field, value, condition) {
    this.filter.push(new Filter(field, value, condition))
  }

  addFilterRaw(expression, values) {
    this.filterRaw = new FilterRaw(expression, values)
  }

  addSearch(field = [], value, condition) {
    var _this = this
    field.forEach(function (f) {
      _this.search.push(new Filter(f, value, condition))
    })
  }
}

export class Filter {
  constructor(field, value, condition) {
    this.field = field
    this.value = value
    this.condition = condition
  }
}

export class Join {
  constructor() {
    this.left = []
    this.inner = []
  }

  addLeft(table, primaryKey, foreignKey) {
    this.left.push(new Left(table, primaryKey, foreignKey))
  }

  addInner(table, primaryKey, foreignKey) {
    this.inner.push(new Left(table, primaryKey, foreignKey))
  }
}

export class Inner {
  constructor(table, primaryKey, foreignKey) {
    this.table = table
    this.primaryKey = primaryKey
    this.foreignKey = foreignKey
  }
}

export class Left {
  constructor(table, primaryKey, foreignKey) {
    this.table = table
    this.primaryKey = primaryKey
    this.foreignKey = foreignKey
  }
}

export class Order {
  constructor(orderby, direction) {
    this.orderby = orderby
    this.direction = direction
  }
}

export class FilterRaw {
  constructor(expression, values) {
    this.expression = expression
    this.values = values
  }
}
