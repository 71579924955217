/* eslint-disable */
import {lista, processar, pegaLista, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {GaleriaSCH, getBaseSCH} from "../../search/GaleriaSCH"
import {EventBus} from '../../helpers/event-bus'
import Galeria from '../../models/galeria'

const url = '/galeria';

const state = {
  all: [],
  galeria: new Galeria(),
  filtro: new Filtro()
};
const getters = {
  listaGalerias: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaGaleria: (state) => {
    return state.galeria
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  setGaleria({commit}, galeria) {
    commit('SET_GALERIA', galeria)
  },
  // searchItem({commit}, search) {
  //   state.filtro.filters.addFilter('descricao' ,
  //     "%"+search+"%",
  //     'like'
  //   )
  // },
  async getItem({commit}, {id,onSucesso, onErro}) {
    try {
      var filtro = getBaseSCH('tp_solucao_id', id, '=');
      const list = (await pegaDados(url + '/get', filtro)).data;
      commit('SET_GALERIAS', list.data[0]);
      onSucesso(list.data[0])
    } catch (error) {
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', GaleriaSCH(perPage))
  },

  async setAllGalerias({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_GALERIAS', list.data[0]);
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
};

const mutations = {
  SET_GALERIAS(state, galerias) {
    state.all = galerias
  },
  SET_GALERIA(state, galeria) {
    state.galeria = galeria
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

