/* eslint-disable */
import {mask} from 'vue-the-mask'

export default {
  directives: {mask},
  data: () => ({
    show_list: false,
    listaItens: [],
    info_alerta: '',
    cor_alerta: 'primary',
    show_alerta: false,
  }),
  methods: {
    // ALERTA
    open_alerta(text, cor) {
      this.info_alerta = text
      this.cor_alerta = cor
      this.show_alerta = true
      var _this = this
      var intervalo = setInterval(function () {
        _this.show_alerta= false
        clearInterval(intervalo)
      }, 5000)
    },
    fechar_alerta() {
      this.show_alerta = false
    },
    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    itens(item) {
      this.listaItens = item
    },
    cortarString(str, tam) {
      if (this.validarCampo(str)) {
        if (str.length > tam) {
          str = str.slice(0, tam) + '...'
        }
      }
      return str
    },
    // MUDA COR DA LINHA
    isActive(id) {
      var toobar_dash = document.getElementsByClassName('toobar_dash')
      var lista = toobar_dash.item(0).children
      for(var i=0; i< lista.length; i++){
        var classes = lista[i].className.split(' ')
        var getIndex = classes.indexOf('toobar_is_active')

        if (lista[i].id === id){
          if (getIndex === -1) {
            classes.push('toobar_is_active')
            lista[i].className = classes.join(' ')
          }
        } else {
          if (getIndex > -1) {
            classes.splice(getIndex, 1)
            lista[i].className = classes.join(' ')
          }
        }
      }
    },
    chunkArray(myArray, n) {
      let tempArray = []
      let chunksize = Math.ceil(Math.max(myArray.length / n, 1))
      for (let index = 0; index < myArray.length; index += chunksize) {
        tempArray.push(myArray.slice(index, index + chunksize))
      }
      return tempArray
    },
    validarCampo(campo) {
      if (campo !== undefined && campo !== null && campo !== '') {
        return true
      } else {
        return false
      }
    },
    getValueByKey(item, key) {
      return item[key] === undefined ? item : item[key]
    },
    scroll_top(){
      var elmnt = document.getElementById("scrolling-techniques-7");
      elmnt.scrollTop = 0
    },
    retira_acentos(str)
    {

      var com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ!"

      var sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr "
      var novastr="";
      for(var i=0; i<str.length; i++) {
        var troca=false;
        for (var a=0; a<com_acento.length; a++) {
          if (str.substr(i,1)===com_acento.substr(a,1)) {
            novastr+=sem_acento.substr(a,1);
            troca=true;
            break;
          }
        }
        if (troca===false) {
          novastr+=str.substr(i,1);
        }
      }
      return novastr;
      },
    scrollTo (id) {
      var elmnt = document.getElementById(id);
      if (this.validarCampo(elmnt)) {
        elmnt.scrollIntoView({behavior: "smooth"});
      }


    },
    maskTel (cpf) {
      // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
      return cpf.replace(/(\d{2,3})(\d{4,5})(\d{4})/g, '(\$1)\$2\-\$3')
    },
    maskCep (cpf) {
      // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
      return cpf.replace(/(\d{2})(\d{3})(\d{3})/g, '\$1.\$2\-\$3')
    },
    trocaIdToobar() {
      // navapp.classList.add("scroll");
      //   navapp.classList.remove("scroll");ent.getElementById('navapp')
      var navapp = document.getElementById('navapp')
      if (this.validarCampo(navapp)) {
        navapp.id = 'navapp_others'
      }
    },
    redimensionando(id){
        var teste_div = document.getElementById(id)
        var eleStyle = window.getComputedStyle(teste_div);
        var height = 760

        var hg = parseInt(eleStyle.width.replace('px', ''))-430
        if (parseInt(eleStyle.width.replace('px', ''))<=1100) {
          height = hg > 300 ? hg : 300
        } else {
          height = hg > 300 ? (hg > 760 ? 760 : hg) : 300
        }
        return height
    },
    ordenarLista(lista, key) {
      lista = lista.sort(function (a, b) {
        return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0
      })
      return lista
    },

    IrParaUrl(path){
      var a = document.createElement('a')
      a.href = path
      a.target = '_blank'
      a.click()
    },
    focusInput(id) {
      var loadTime = 10
      window.onload = function () {
        loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
      }
      var interval = setInterval(function () {
        var muniImpt = document.getElementById(id)
        if (muniImpt !== null) {
          muniImpt.focus()
        }

        clearInterval(interval)
      }, loadTime)
    },
  },
}
