/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Anexo {
  constructor (url, src, capa, mantemExtensao) {

    this.anexo_id = uuidV4()
    this.url = url
    this.src = src
    this.capa = capa
    this.mantem_extensao = mantemExtensao
  }
}
